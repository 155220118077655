








































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import PhoneInput from "@/components/util/PhoneInput.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import AutoComplete from "@/components/common/AutoComplete.vue";
import W9CRContractorDTO from "@/dto/request/w9cr/W9CRContractorDTO";
import {FormFiller, W9CRContractorStatus} from "@/constants/request/W9CRConstants";
import {InputType} from "@/components/common/InputType";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import W9CRCompanyData from "@/components/request/w9cr/W9CRCompanyData.vue";
import W9CRPersonData from "@/components/request/w9cr/W9CRPersonData.vue";
import W9CRService from "@/services/request/W9CRService";
import {namespace} from "vuex-class";
import {ifValid} from "@/utils/ComponentUtils";

const AppModule = namespace("App");

@Component({
  computed: {
    FileMetaDTO() {
      return FileMetaDTO
    },
    W9CRContractorStatus() {
      return W9CRContractorStatus
    },
    InputType() {
      return InputType
    },
    AutoCompleteDTO() {
      return AutoCompleteDTO
    },
    FormFiller() {
      return FormFiller
    }
  },
  components: {W9CRCompanyData, W9CRPersonData, PhoneInput, PortalInput, AutoComplete, SingleFileHolder, PortalCollapse}
})
export default class W9CRContractorItem extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop()
  private contractor!: W9CRContractorDTO;

  uploadW9Lambda: (file: FileMetaDTO) => void = (file: FileMetaDTO) => {
    return (file: FileMetaDTO) => {
      this.w9Provided()
    };
  }

  w9Provided() {
    this.startLoading();
    this.contractor.status = W9CRContractorStatus.PENDING_FOR_APPROVAL
    W9CRService.saveContractorByCustomer(this.id, this.contractor).then(
        ok => {
          this.stopLoading();
        },
        err => {
          console.log(JSON.stringify(err));
          this.stopLoading();
        }
    )
  }

  get id(){
    return this.$route.params.id;
  }

  sendToContractor() {
    ifValid(this, () => {
      this.contractor.status = W9CRContractorStatus.FILLING_BY_CONTRACTOR;
      this.save()
    })
  }

  cancel() {
    this.contractor.status = W9CRContractorStatus.CANCELLED_BY_CUSTOMER;
    this.save()
  }

  fillByContractor(){
    this.contractor.formFiller = FormFiller.CONTRACTOR;
    this.save();
  }

  save(){
    this.startLoading();
    W9CRService.saveContractorByCustomer(this.$route.params.id, this.contractor).then(
        ok => {
          this.stopLoading();
          console.log("OK")
        },
        err => {
          console.log(JSON.stringify(err))
          this.contractor.status = W9CRContractorStatus.FILLING_BY_CUSTOMER;
          this.stopLoading();
        }
    )
  }

}
